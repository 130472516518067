document.addEventListener('DOMContentLoaded', function () {
  // Function to open the popup
  function openPopup(popupId) {
    let template = document.getElementById(`popup-template-${popupId}`);
    if (template) {
      let clone = document.importNode(template.content, true);
      document.body.appendChild(clone);

      // Attach close event to the new popup
      let popup = document.getElementById(`popup-${popupId}`);
      setTimeout(function () {
        popup.classList.add('is-open');
      }, 20);
      popup.addEventListener('click', function (event) {
        console.error(event.target.classList);
        if (event.target === popup || event.target.classList.contains('popup-close')) {
          closePopup(popup);
        }
      });
      document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
          closePopup(popup);
        }
      });
    }
  }

  // Function to close the popup
  function closePopup(popup) {
    document.body.removeChild(popup);
  }

  // Attach click listeners to links and buttons
  document.querySelectorAll('a[href^="#popup-"], button[href^="#popup-"]').forEach(function (element) {
    element.addEventListener('click', function (event) {
      event.preventDefault();
      let popupId = this.getAttribute('href').substring(7); // Remove the "#popup-" part

      openPopup(popupId);
    });
  });

  // Check if the URL contains a popup ID and open the popup
  let {
    hash
  } = window.location;
  if (hash.startsWith('#popup-')) {
    let popupId = hash.substring(7); // Remove the "#popup-" part

    openPopup(popupId);
  }
});